// core
import {mapGetters, mapState} from 'vuex';

// helpers
import {slugify} from '@/Helpers/Misc';

// components
import InterseptedImage from '@/Views/_Components/Helpers/InterseptedImage/index.vue';

// icons
import {HeartIcon, PlusCircleIcon} from 'vue-feather-icons';

export default {
  name: 'OstonMagazines',

  props: {
    title: {
      type: String,
      required: true,
      default: () => console.log('Oston Magazines: The TITLE is required.')
    },
    method: {
      type: String,
      required: false,
      default: () => 'magazines'
    }
  },

  mounted() {
    if (this.method == 'magazines') {
      _.controller('magazine').getAll();
    }
    if (this.method == 'magazinesHighlights') {
      _.controller('magazine').getAllHighlights();
    }
    if (this.method == 'magazinesRecommended') {
      _.controller('magazine').getRecommended();
    }
  },

  components: {
    'oston-intersepted-image': InterseptedImage,
    'feather-icon-heart': HeartIcon,
    'feather-icon-plus': PlusCircleIcon
  },

  methods: {
    slugify,
    select: function (id) {
      _.controller('magazine').get(id);
    }
  },

  computed: {
    ...mapGetters({
      magazines: 'MagazineModel/magazines'
    }),

    ...mapState({
      list(state, getters) {
        return getters[`MagazineModel/${this.method}`]
      }
    })
  }
};
