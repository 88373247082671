import {mapGetters} from 'vuex';0

import {slugify} from '@/Helpers/Misc';

export default {
  name: "Banner",

  mounted() {
    _.controller('magazine').getBanner();
  },

  computed: {
    ...mapGetters({
      banner: 'MagazineModel/banner'
    })
  }
}
